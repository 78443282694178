<template>
  <section class="app-ecommerce-details">
    <b-overlay :show="loading">
      <b-row v-if="data" class="my-2">
        <b-col cols="12" md="7">
          <b-card-text class="item-company">
            <b-badge pill class="mr-75" variant="primary">
              {{ data.category.name }}
            </b-badge>
          </b-card-text>
          <h4 class="mb-3">{{ data.name }}</h4>

          <b-card-text>Tentang buku ini </b-card-text>

          <b-card-text><span v-html="data.description"></span></b-card-text>
        </b-col>

        <b-col cols="12" md="5" class="align-items-center justify-content-center mb-2 mb-md-0">
          <b-card>
            <b-card class="border">
              <center>
                <b-img :src="apiFile + data.photo" style="width: 70%" :alt="`Image of ${data.name}`" class="data-img" fluid />
              </center>
              <h6 class="mt-1">Harga</h6>
              <h3 class="text-primary mb-1" v-if="data.price > 0">Rp{{ formatRupiah(data.price) }}</h3>
              <h3 class="text-primary mb-1" v-else>Gratis</h3>
              <feather-icon icon="CalendarIcon" class="mr-50" />
              <small class="font-weight-bold">Terbit {{ data.publication_year }}</small>
              <feather-icon icon="BookOpenIcon" class="mr-50 ml-2" />
              <small class="font-weight-bold">{{ data.num_page }} halaman</small>
            </b-card>
            <div class="" v-if="status == 'belum-dimiliki'">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-cart btn-block" :to="{ name: 'payment-ebook', params: { id: data.slug } }" v-if="data.price > 0">
                <span>Beli</span>
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-cart btn-block mt-1" @click="saveEbook" v-else>
                <span> Tambahkan</span>
              </b-button>
            </div>
            <div class="" v-else>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-cart btn-block" @click="downloadEbook">
                <span>Unduh E-book</span>
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-cart btn-block mt-1" @click="$refs.bacaFile.click()">
                <span> <feather-icon icon="BookOpenIcon"></feather-icon> Baca</span>
              </b-button>

              <a ref="bacaFile" :href="apiFile + data.file_ebook.file" target="_blank"></a>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import { BOverlay, BBadge, BCard, BCardBody, BRow, BCol, BImg, BCardText, BLink, BButton, BDropdown, BDropdownItem, BAlert } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BOverlay,
    BBadge,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,

    // SFC
  },
  computed: {
    apiFile() {
      return "http://file.elp-dev.my.id/";
    },
  },
  data() {
    return {
      loading: false,
      data: {},
      status: this.$route.params.status,
    };
  },
  methods: {
    async downloadEbook() {
      const isEmpty = !this.data.file_ebook;
      if (isEmpty) return;

      let fileName = this.data.file_ebook?.file.split("/");
      fileName = fileName[fileName.length - 1].split(".");
      fileName = fileName[0] + ".zip";

      const payload = {
        path: this.data.file_ebook?.file,
      };
      this.loading = true;
      const rawFile = await this.$store.dispatch("e-book/downloadFile", payload);
      const href = URL.createObjectURL(rawFile);
      const a = document.createElement("a");
      a.setAttribute("href", href);
      a.setAttribute("download", fileName);
      a.setAttribute("target", "_blank");
      a.click();
      URL.revokeObjectURL(href);
      this.loading = false;
    },
    getData() {
      this.loading = true;
      let id = this.$route.params.slug;

      this.$store
        .dispatch("e-book/detail", id)
        .then((res) => {
          this.loading = false;
          this.data = res.data;
          this.$store.commit("ebook/SET_DETAIL", res.data);
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    saveEbook() {
      this.loading = true;
      let payload = {
        ebook_id: this.data.id,
      };

      this.$store
        .dispatch("e-book/saveEbookGratis", [payload])
        .then((res) => {
          this.loading = false;
          this.displaySuccess({
            text: "E-book Tersimpan, Silahkan Lihat di E-book Saya",
          });
          this.$router.push({
            name: "ebook",
          });
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
